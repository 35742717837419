import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Flex, Heading, Text } from "@chakra-ui/core";
import Scrollspy from "react-scrollspy";
import { url } from "../../const";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function Posts() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              date
              title
              author
              type
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `);

  const allData = data.allMarkdownRemark.edges;

  const dateIds = [];
  allData.forEach(({ node }) => {
    const date = new Date(node.frontmatter.date);
    const monthWithYear = `${months[date.getMonth()]}-${date.getFullYear()}`;
    node.dateId = monthWithYear;
    if (!dateIds.includes(monthWithYear)) {
      dateIds.push(monthWithYear);
    }
  });
  return (
    <Layout socials={true}>
      <SEO title="All Posts" url={`${url}/posts`} />
      <Flex mt={"2rem"} flexDirection="column" width={["auto", "42rem"]}>
        <Heading as="h2" fontSize={30} mb={"0.5rem"}>
          Posts
        </Heading>
        {allData.map(({ node }) => (
          <Flex
            key={node.id}
            id={node.dateId}
            mt={"1rem"}
            flexDirection="column"
          >
            <Heading as="h2" fontSize={24}>
              <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
            </Heading>
            <Text
              fontFamily={"Raleway, sans-serif"}
              style={{
                color: node.frontmatter.type === "student" ? "#EB3978" : "",
              }}
            >
              {node.frontmatter.author}
            </Text>
            <Text mt={"0.5rem"}>{node.excerpt}</Text>
          </Flex>
        ))}
      </Flex>
      <Flex
        position="fixed"
        display={["none", "none", "flex"]}
        right={"15%"}
        top={"30%"}
        mr={"-50px"}
      >
        <Scrollspy items={dateIds} currentClassName="is-current">
          {dateIds.map(item => (
            <Text key={item} mt={5} className="transition">
              <a href={`#${item}`}>
                <span style={{ fontSize: 9, color: "#ff9048" }}>►</span>{" "}
                {item.replace("-", " ")}
              </a>
            </Text>
          ))}
        </Scrollspy>
      </Flex>
    </Layout>
  );
}
